












import { DataTableColDef, DataTableDef, DataTableRowDef } from '@/app_code/DataTable'
import DataTable from '@/components/UI/Elements/DataTable/DataTable.vue'
import { EnumerationGroupModel, EnumValueType } from 'truemarket-modules/src/models/api'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    DataTable
  }
})
export default class EnumerationsIndex extends Vue {
  private enumGroups: EnumerationGroupModel[] = []

  private dtDef = new DataTableDef(new DataTableRowDef(row => row.CountryId, [
    new DataTableColDef('Type', row => row.FormattedType),
    new DataTableColDef('Count', row => row.Count)
  ]))

  loadData () {
    Services.API.Enumerations.GetGroups().then((groups) => {
      this.enumGroups = groups
    })
  }

  mounted () {
    this.loadData()
  }

  editItem (itemId: EnumValueType) {
    this.$router.push(`/dashboard/admin/enumerations/${itemId}`)
  }
}
